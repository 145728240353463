import React from "react";
import _ from "lodash";
import { navigate } from "gatsby";

import { Link, withPrefix, classNames } from "../utils";
import ActionLink from "./ActionLink";

export default class Header extends React.Component {
  render() {
    console.log(this.props);
    return (
      <header id="masthead" className="site-header outer">
        <div className="inner">
          <div className="site-header-inside">
            <div className="site-branding">
              {_.get(
                this.props,
                "pageContext.site.siteMetadata.header.logo_img",
                null
              ) && (
                <p className="site-logo">
                  <Link to={withPrefix("/")}>
                    <img
                      src={withPrefix(
                        _.get(
                          this.props,
                          "pageContext.site.siteMetadata.header.logo_img",
                          null
                        )
                      )}
                      alt={
                        _.get(
                          this.props,
                          "pageContext.site.siteMetadata.header.title",
                          null
                        ) + " Logo"
                      }
                    />
                  </Link>
                </p>
              )}
              {_.get(this.props, "pageContext.frontmatter.template", null) ===
              "home" ? (
                <h1 className="site-title">
                  <Link to={withPrefix("/")}>
                    {_.get(
                      this.props,
                      "pageContext.site.siteMetadata.header.title",
                      null
                    )}
                  </Link>
                </h1>
              ) : (
                <p className="site-title">
                  <Link to={withPrefix("/")}>
                    {_.get(
                      this.props,
                      "pageContext.site.siteMetadata.header.title",
                      null
                    )}
                  </Link>
                </p>
              )}
            </div>
            {_.get(
              this.props,
              "pageContext.site.siteMetadata.header.has_nav",
              null
            ) && (
              <React.Fragment>
                <button id="menu-open" className="menu-toggle">
                  <span className="screen-reader-text">Menu</span>
                  <span className="icon-menu" aria-hidden="true" />
                </button>
                <nav
                  id="main-navigation"
                  className="site-nav"
                  aria-label="Main Navigation"
                >
                  <div className="site-nav-inside">
                    <button id="menu-close" className="menu-toggle">
                      <span className="screen-reader-text">Menu</span>
                      <span className="icon-close" aria-hidden="true" />
                    </button>
                    <ul className="menu">
                      {_.map(
                        _.get(
                          this.props,
                          "pageContext.site.siteMetadata.header.nav_links",
                          null
                        ),
                        (action, action_idx) => (
                          <li
                            key={action_idx}
                            className={classNames("menu-item", {
                              "current-menu-item":
                                _.get(this.props, "pageContext.url", null) ===
                                _.get(action, "url", null),
                              "menu-button":
                                _.get(action, "style", null) !== "link",
                            })}
                          >
                            <ActionLink {...this.props} action={action} />
                          </li>
                        )
                      )}
                      <button
                        className="header-button"
                        onClick={() => {
                          if (!this.props.setIsLovedFiltered) {
                            navigate("/");

                            return;
                          }

                          this.props.setIsLovedFiltered(
                            !this.props.isLovedFiltered
                          );
                        }}
                      >
                        <i
                          className={`fa-heart ${
                            this.props.isLovedFiltered ? "fas" : "far"
                          }`}
                        ></i>
                      </button>
                    </ul>
                  </div>
                </nav>
              </React.Fragment>
            )}
          </div>
        </div>
      </header>
    );
  }
}
